@use '../variables' as v;
@use 'layout' as layout;

/*
 *  # SCSS Styling Mixins V1.0
 *  These mixins are made to quickly build SCSS layouts.
 *
 */

/*
 * # Background overlay
 * Adds a dark overlay as :after pseudo selector to backgrounds
 */
@mixin bg-overlay($intensity: 0.56, $width-lg: 80%, $width-xl: 60%) {
  &:after {
    @include layout.position('absolute', 0, 0, 0, 0);
    content: '';
    background: rgba(0, 0, 0, $intensity);

    @include layout.bp(lg) {
      background: linear-gradient(to right, rgba(0, 0, 0, $intensity) $width-lg, rgba(0, 0, 0, 0.00) 100%);
    }

    @include layout.bp(xl) {
      background: linear-gradient(to right, rgba(0, 0, 0, $intensity) $width-xl, rgba(0, 0, 0, 0.00) 100%);
    }
  }
}

/*
 * # Theme color loop
 * Loops over all theme colors to generate theme color variants
 *
 * Available variables in @content:
 * - $type | The theme color type, either 'brand' or 'state'.
 * - $color | The current theme color name.
 */
$gtc-type: global null;
$gtc-color: global null;

@mixin generateThemeColors($mode: 'theme') {

  @if ($mode == 'theme') {

    @each $type, $colors in v.$themeColors {
      $gtc-type: $type !global;

      @each $color in $colors {
        $gtc-color: $color !global;
        @content;
      }
    }

  } @else if ($mode == 'brand') {
    $gtc-type: 'brand' !global;

    @each $color in v.$brandColors {
      $gtc-color: $color !global;
      @content;
    }

  } @else if ($mode == 'state') {
    $gtc-type: 'state' !global;

    @each $color in v.$stateColors {
      $gtc-color: $color !global;
      @content;
    }

  }
}

/*
 * # Card styling
 * All card styling properties in a single mixin.
 */
@mixin card-style {
  background: var(--cards-background);
  border: var(--card-border-property);
  border-radius: var(--shape-border-radius);
}

@mixin background-blur {
  background: linear-gradient(to right, var(--page-background), rgba(var(--page-background-rgb), 0.8));
  backdrop-filter: blur(8px);
}