@use '../../../../../../resources/scss/mixins' as m;

@mixin background-style {
  background: var(--text-block-background);
  border-width: var(--text-block-border, 0);
  border-style: solid;
  border-color: var(--text-block-border);
  border-radius: var(--shape-border-radius);
  overflow: hidden;
}

.text-block {
  grid-auto-flow: dense;

  &[data-media] {
    min-height: 320px;

    @include m.bp(md) {
      min-height: 360px;
    }

    @include m.bp(xl) {
      min-height: 400px;
    }
  }

  &[data-background]:not([data-media]) &__copy {
    @include background-style;
  }

  &[data-background] &__copy {
    padding: 0 24px 16px;

    @include m.bp(sm) {
      padding: 24px 32px;
    }

    @include m.bp(lg) {
      padding: 32px 40px;
    }

    @include m.bp(xl) {
      padding: 40px 48px;
    }
  }

  &[data-background][data-media] {
    @include background-style;

    @include m.bp(xl) {
      grid-template-columns: repeat(18, 1fr);

      .text-block__copy {
        grid-column: span 8 / -2;
      }
    }
  }

  &[data-background] .picture, &[data-background] .video {
    border-radius: 0;
  }

  &__copy {
    grid-column: 1 / -1;

    @include m.bp(sm) {
      grid-column: 2 / -2;
    }

    @include m.bp(lg) {
      grid-column: 3 / -3;
    }

    @include m.bp(xl) {
      grid-column: 5 / -5;
    }

    .heading {
      margin-top: unset;
      max-width: unset;

      @include m.bp(sm) {
        &__title {
          padding-top: unset;
        }
      }
    }

    &__actions {
      @include m.flex(start, center);
      gap: 8px;

      .button {
        margin: 1.5rem 0 0;
      }
    }
  }

  .picture + &__copy, .video + &__copy {
    grid-column: 1 / -1;
    align-self: center;

    @include m.bp(sm) {
      grid-column: span 6 / -1;
      grid-row: 1;
      padding: 16px 0;
    }

    @include m.bp(md) {
      padding: 24px 0;
    }

    @include m.bp(lg) {
      grid-column: span 8 / -2;
      padding: 32px 0;
    }

    @include m.bp(xl) {
      padding: 40px 0;
    }

    &__link {
      grid-column: 1 / -1;
      margin: 16px 0 0;

      @include m.bp(sm) {
        margin: 24px 0 0;
      }

      &__icon {
        margin-left: 6px
      }
    }
  }

  .picture, .video {
    position: relative;
    grid-column: 1 / -1;
    min-height: 240px;
    max-height: 320px;
    border-radius: var(--shape-border-radius);
    overflow: hidden;

    @include m.bp(sm) {
      @include m.position(absolute, 0, 0, 0, 0);
      @include m.flex;
      grid-column: 1 / span 5;
      max-height: unset;
    }

    @include m.bp(lg) {
      grid-column: 1 / span 8;
    }

    .image {
      height: 100%;
    }
  }

  /* Styling for even cards */
  &:nth-of-type(even):has(* + &__copy) {

    .picture, .video {
      @include m.bp(sm) {
        grid-column: span 5 / -1;
      }

      @include m.bp(lg) {
        grid-column: span 8 / -1;
      }
    }

    .text-block__copy {
      @include m.bp(sm) {
        grid-column: 1 / span 6;
      }

      @include m.bp(lg) {
        grid-column: 2 / span 8;
      }
    }
  }

  &:nth-of-type(even) & {
    .video, .picture {

      @include m.bp(sm) {
        grid-column: span 5 / -1;
      }

      @include m.bp(xl) {
        grid-column: span 8 / -1;
      }
    }
  }

  @include m.bp(sm) {
    &:nth-of-type(even)[data-background][data-media] &__copy {
      padding-right: 0;
    }

    &:nth-of-type(odd)[data-background][data-media] &__copy {
      padding-left: 0;
    }
  }
}