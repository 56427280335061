/*
 * Variables
 */

/* Grid sizing */
$grid-col-xs: 6;
$grid-col-sm: 12;
$grid-col-md: 12;
$grid-col-lg: 12;
$grid-col-xl: 18;

/* Page and section sizing */
$section-page-content-width: 960px;
$search-result-max-width: 1600px;

/* Font weights */
$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-black: 900;

/* z-indexes */
$z-page-overlay-behind-nav: 800;
$z-nav-page-overlay: 840;
$z-cookie-banner: 850;
$z-overlay-behind-nav: 860;
$z-action-nav: 870;
$z-nav-sub-menu: 880;
$z-stripe-loading-indicator: 890;
$z-nav-menu: 900;
$z-action-nav-dropdown: 920;
$z-filter-menu: 1000;
$z-side-menu-overlay: 1300;
$z-modal-overlay: 1400;
$z-privacy-manager: 1500;
$z-notification: 1600;

/* Select2 dropdown */
$z-dropdown: 2000;

/* Button sizing */
$button-size: 44px;

/* Image alignments and ratios */
$alignment-positions: (0, 25, 50, 75, 100);

/* Section padding */
$section-padding-sizes: (xs: 16px, sm: 24px, md: 40px, lg: 64px);

/*
 *  Overlays
 */
$overlay-backdrop-hidden: rgba(0, 0, 0, 0.01);
$overlay-backdrop-visible: rgba(0, 0, 0, 0.24);
$overlay-transition-time: 400ms;
$overlay-transition-in: cubic-bezier(0.22, 1, 0.36, 1);
$overlay-transition-out: cubic-bezier(0.64, 0, 0.78, 0);
$side-menu-transition-in: cubic-bezier(0.64, 0, 0.78, 0);
$side-menu-transition-out: cubic-bezier(0.22, 1, 0.36, 1);

/* Logo sizes for navigation and footer */
$logo-sizes: (
        'any': ('xs': 24px, 'sm': 32px, 'md': 40px, 'lg': 48px, 'xl': 56px),
        'sm': ('xs': 32px, 'sm': 40px, 'md': 48px, 'lg': 56px, 'xl': 64px),
        'lg': ('xs': 40px, 'sm': 48px, 'md': 56px, 'lg': 64px, 'xl': 72px)
);

$header-logo-sizes: (
        'any': ('xs': 32px, 'sm': 48px, 'md': 64px, 'lg': 128px, 'xl': 160px),
        'sm': ('xs': 36px, 'sm': 56px, 'md': 80px, 'lg': 160px, 'xl': 192px),
        'lg': ('xs': 40px, 'sm': 64px, 'md': 96px, 'lg': 192px, 'xl': 240px)
);

/* Theme colors */
$brandColors: (main, accent);
$stateColors: (info, success, warning, error);
$themeColors: (button: $brandColors, state: $stateColors);

/* Grid columns and gaps */
:root {
  --grid-gap-xs: 16px;
  --grid-gap-sm: 24px;

  --grid-col-xs: 6;
  --grid-col-sm: 12;
  --grid-col-xl: 18;

  --max-grid-width: calc(var(--grid-width) * var(--grid-col-xl) + var(--grid-gap-sm) * var(--grid-col-xl));
}

/*
 * Transitions
 */
$button-transition: background 0.24s, color 0.24s, border-color 0.24s;
$input-transition: background 0.24s, color 0.24s, border-color 0.24s;

$fallback-image-transition: color 0.24s, opacity 0.24s;
$transparent-image-transition: opacity 0.24s;

$page-overlay-transition: opacity 0.64s cubic-bezier(0.22, 1, 0.36, 1), height 0s 0.64s;

$section-move-in-transition: transform 0.64s cubic-bezier(0.22, 1, 0.36, 1);
$section-move-out-transition: transform 0.64s cubic-bezier(0.64, 0, 0.78, 0);

/* Element shadows */
$shadow-section: 0 0 12px rgba(0, 0, 0, 0.16);
$shadow-overlay: 0 0 12px rgba(0, 0, 0, 0.16);
$shadow-notification: 0 0 12px rgba(0, 0, 0, 0.16);