@use '../../../../../../resources/scss/mixins' as m;

.cards {

  .scroll-handler-container__slider {
    @include m.flex(start, stretch);

    .card {
      flex: 0 0 84%;
      max-width: 400px;
      margin-top: unset;
    }
  }

  &__copy {
    grid-column: 1 / -1;
    max-width: 640px;
  }

  > .button {
    grid-column: 1 / -1;
    margin-top: 16px;
    width: auto;
    display: flex;

    @include m.bp(sm) {
      justify-self: end;
    }
  }
}

.card {
  @include m.flex(between, center, column);
  grid-column: 1 / -1;
  margin: 24px 0 0;
  text-decoration: none;

  @include m.bp(sm) {
    grid-column: span 6;
  }

  &[href] {
    &, &:visited, &:hover, &:visited:hover {
      color: inherit;
    }
  }

  &--background {
    background: var(--cards-background);
    border-width: var(--card-border, 0);
    border-style: solid;
    border-color: var(--card-border);
    border-radius: var(--shape-border-radius);
    box-shadow: var(--element-shadow);
    overflow: hidden;

    &[href] {

      @include m.interaction() {
        border-color: var(--brand-main-active);
        background: linear-gradient(rgba(var(--brand-main-active-rgb), 0.12), rgba(var(--brand-main-active-rgb), 0.12)), var(--cards-background);

        .button {
          background: var(--brand-main-active);
        }
      }
    }
  }

  &--background &__content {
    padding: 0 16px;
    box-sizing: border-box;
  }

  &--background {

    .heading__title, .heading__description * {
      color: var(--text-color-dark);
    }

    &[data-dark-mode] {
      .heading__title, .heading__description * {
        color: var(--text-color-light);
      }
    }

    .picture, .video video {
      border-radius: unset;
      border-bottom: 1px solid var(--card-border);
    }
  }

  &--wide {
    @include m.bp(lg) {
      grid-column: span 6;
    }

    @include m.bp(xl) {
      grid-column: span 9;
    }
  }

  &__content {
    @include m.flex(between, start, column);
    flex: 1;
    width: 100%;
    margin: 12px 0;

    &__note {
      @include m.flex(start, center);
      margin: 0;
      color: var(--mono-6);

      &__icon {
        padding: 0 8px 0 0;
        font-size: 16px;
      }
    }

    .heading {
      flex: 1 0 auto;
      margin-top: 0;
      width: 100%;

      &__title {
        padding-top: 0.2rem;

        @include m.bp(sm) {
          gap: 8px;
        }

        &__icon {
          font-size: inherit;
          margin-right: 4px;
        }
      }

      &__description p {
        margin: 0.2rem 0 0;
      }
    }

    .button {
      flex: 0 0 auto;
      margin-top: 1rem;
      width: 100%;
      box-sizing: border-box;
    }
  }
}