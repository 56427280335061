@use '../../../../../../resources/scss/mixins' as m;

.banner-block {
  grid-auto-flow: dense;
  min-height: 320px;

  &--card-background {
    background: var(--cards-background);
    border: 1px solid var(--card-border);
  }

  &__copy {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    align-self: center;
    z-index: 10;
    margin: 16px;

    @include m.bp(sm) {
      padding: 64px 0;
      margin: 0 24px;
    }

    @include m.bp(xl) {
      margin: 0 32px;
    }

    @include m.bp(lg) {
      margin: 0 40px;
    }

    &--align {
      &-center {
        align-items: center;
        text-align: center;
      }

      &-right {
        align-items: flex-end;
      }
    }

    .heading {
      &__title {
        padding-top: 0;
      }
    }

    &__actions {
      @include m.flex(start, stretch, column);
      gap: 8px;
      grid-column: 1 / -1;
      margin: 16px 0 0;

      @include m.bp(sm) {
        flex-direction: row;
        width: auto;
        margin: 24px 0 0;
        gap: 16px;
      }

      &__icon {
        margin-left: 6px
      }
    }
  }

  &__background {
    @include m.position(absolute, 0, 0, 0, 0);
    height: 100%;
    z-index: 0;

    &:after {
      @include m.position(absolute, 0, 0, 0, 0);
      content: '';
      background: rgba(255, 255, 255, 0.56);
      border-width: var(--banner-border, 0);
      border-style: solid;
      border-color: var(--banner-border);
      border-radius: var(--shape-border-radius);
    }

    &__heading {
      text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    }

    &__picture {
      width: 100%;
      height: 100%;

      .image {
        height: 100%;
        border-radius: 0;
      }
    }
  }

  &[data-dark-mode] &__background:after {
    background: rgba(0, 0, 0, 0.56);
  }
}