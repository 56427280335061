@use '../../../../../../resources/scss/mixins' as m;

.photo-gallery {

  .heading + &__grid {
    margin-top: 24px;
  }

  .picture {
    height: 100%;
  }

  &__actions {
    grid-column: 1 / -1;
    margin: 16px 0;
  }

  &__grid {
    @include m.grid(8, 16, 16, 16, 1fr);
    grid-column: 1 / -1;
    grid-gap: 8px;
    grid-template-areas:
          'p1 p1 p1 p1 p2 p2 p2 p2'
          'p3 p3 p3 p3 p4 p4 p4 p4';

    @include m.bp(sm) {
      grid-gap: 16px;
    }

    &__container {
      margin-top: 0;

      @for $number from 1 through 5 {
        &:nth-of-type(#{$number}) {
          grid-area: p#{$number};
        }
      }

      &:nth-of-type(5) {
        display: none;
      }
    }

    /* Gallery layouts */
    &--gallery {

      @include m.bp(sm) {
        grid-template-areas:
              'p1 p1 p1 p1 p2 p2 p2 p2 p3 p3 p3 p3 p4 p4 p4 p4';
      }
    }

    &--grid {
      @include m.bp(sm) {
        grid-template-areas:
              'p1 p1 p1 p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p2 p2 p2'
              'p3 p3 p3 p3 p3 p3 p3 p3 p4 p4 p4 p4 p4 p4 p4 p4';
      }
    }

    &--masonry-a {
      grid-template-areas:
          'p1 p1 p1 p2 p2 p2 p2 p2'
          'p3 p3 p3 p3 p3 p3 p3 p3'
          'p4 p4 p4 p4 p4 p5 p5 p5';

      @include m.bp(sm) {
        grid-template-areas:
              'p1 p1 p1 p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p2 p2 p2'
              'p3 p3 p3 p3 p3 p4 p4 p4 p4 p4 p4 p5 p5 p5 p5 p5';
      }
    }

    &--masonry-a &__container:nth-of-type(5) {
      display: initial;
    }

    &--masonry-b {
      grid-template-areas:
          'p1 p1 p1 p2 p2 p2 p2 p2'
          'p3 p3 p3 p3 p3 p4 p4 p4';

      @include m.bp(sm) {
        grid-template-areas:
              'p1 p1 p1 p1 p2 p2 p2 p2 p2 p2 p2 p2 p2 p2 p2 p2'
              'p3 p3 p3 p3 p3 p3 p3 p3 p3 p4 p4 p4 p4 p4 p4 p4';
      }
    }

    &--masonry-c {
      grid-template-areas:
          'p1 p1 p1 p1 p3 p3 p3 p3'
          'p2 p2 p2 p2 p3 p3 p3 p3'
          'p4 p4 p4 p4 p4 p4 p4 p4';

      @include m.bp(sm) {
        grid-template-areas:
          'p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p3 p3 p3 p3 p3 p3'
          'p4 p4 p4 p4 p4 p4 p4 p4 p4 p4 p3 p3 p3 p3 p3 p3';
      }
    }

    &--masonry-d {
      grid-template-areas:
          'p1 p1 p1 p1 p1 p1 p1 p1'
          'p1 p1 p1 p1 p1 p1 p1 p1'
          'p2 p2 p2 p2 p3 p3 p3 p3'
          'p4 p4 p4 p4 p4 p4 p4 p4';

      @include m.bp(sm) {
        grid-template-areas:
          'p1 p1 p1 p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p3 p3 p3 p3'
          'p1 p1 p1 p1 p1 p1 p1 p1 p4 p4 p4 p4 p4 p4 p4 p4 p4';
      }
    }
  }
}

.photo-block-lightbox {
  border-radius: var(--shape-border-radius);

  @include m.bp(null, sm) {
    margin: auto 16px !important;
  }

  .section__content {
    height: 100%;
    overflow: hidden;

    .picture {
      height: 100%;
      width: 100%;
      border-radius: 0;

      .image {
        object-fit: contain;
      }
    }
  }
}

// TODO: Check if this modifier should be an option in Page Overlays
#overlay-modal-photo-block-lightbox[data-overlay-state='visible'] {
  background: rgba(0, 0, 0, 0.8);
}