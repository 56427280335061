@use '../../../../../../resources/scss/variables' as v;
@use '../../../../../../resources/scss/mixins' as m;

.citations {
  @include m.scroll-box-sizing(40px);
  color: var(--text-color-dark);
  padding: var(--section-padding) 16px !important;

  @include m.bp(sm) {
    padding: var(--section-padding) 24px !important;
  }

  @include m.bp(md) {
    padding: var(--section-padding) 40px !important;
  }

  &[data-dark-mode] {
    color: var(--text-color-light);

    .heading__title {
      color: var(--heading-color-light);
    }
  }

  &--card {
    background: var(--cards-background);
    border: 1px solid var(--card-border);
    border-radius: var(--shape-border-radius);
  }

  .heading {
    position: relative;
    z-index: 10;

    &__title {
      color: var(--text-color-dark);
    }

    &__description {
      color: inherit;
    }
  }

  &__background {
    @include m.position(absolute, 0, 0, 0, 0);
    @include m.bg-overlay(0.56, 100%, 100%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: unset;
    z-index: 0;

    .video__media {
      border-radius: unset;
    }
  }

  &[data-background] {
    width: 100%;
    margin: 0;
    border-radius: unset;
  }
}

.citations .scroll-handler-container {

  &__slider {
    @include m.flex(start, center);
    mask-image: unset;
  }

  &__buttons {
    margin-top: 16px;
    @include m.section-padding-sizes(margin);
  }
}