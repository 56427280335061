@use '../../../../../../resources/scss/mixins' as m;

.link-list {
  &__copy {
    grid-column: 1 / -1;
    margin: 0 0 32px;
  }

  &__list {
    @include m.flex(start, center);
    flex-wrap: wrap;
    gap: 8px;
    grid-column: 1 / -1;

    @include m.bp(null, sm) {
      @include m.inputMethod(touch) {
        @include m.hide-scrollbars();
        overflow-x: scroll;
        flex-wrap: nowrap;
        margin: 0 -16px;

        &:before, &:after {
          content: '_';
          flex: 0 0 8px;
          height: 0;
          color: transparent;
          pointer-events: none;
        }
      }
    }
  }
}