@use '../../../../../../resources/scss/variables' as v;
@use '../../../../../../resources/scss/mixins' as m;

// Switch list container
.switch-list {

  &__blocks {
    @include m.grid();
    grid-column: 1 / -1;
    grid-template-rows: 48px auto;
    margin-top: 16px;
    grid-row-gap: 16px;

    @include m.bp(sm) {
      margin-top: 32px;
      grid-row-gap: 8px;
    }
  }
}

// Switch list content block
.switch-list-block {
  display: grid;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  grid-column-gap: 12px;

  &__preview {
    @include m.flex(center, center);
    position: relative;
    box-sizing: border-box;
    grid-row: 1;

    @include m.bp(sm) {
      grid-column: 1 / 3;
    }

    @include m.bp(md) {
      grid-column: 1 / 5;
      justify-content: start;
      align-self: center;
      gap: 12px;
      padding: 12px 8px 12px 12px;

      &:after, &:before {
        @include m.position(absolute, 0, 0, 0, 0);
        content: '';
        border-radius: var(--shape-border-radius);
        transition: opacity 0.48s cubic-bezier(0.33, 1, 0.68, 1);
      }

      &:before {
        background: linear-gradient(to right, var(--cards-background) 50%, transparent 75%);
        z-index: -2;
        background-size: 300%;
      }

      &:after {
        border: var(--card-border-property);
        z-index: -1;
      }

      @include m.interaction() {
        &:after, &:before {
          opacity: 0.5 !important;
        }
      }

      @include m.interaction(active) {
        &:after, &:before {
          opacity: 0.8 !important;
        }
      }
    }

    @include m.bp(lg) {
      padding: 16px 12px 16px 16px;
    }

    @include m.bp(xl) {
      grid-column: 1 / 6;
    }

    &__icon {
      @include m.flex(center, center);
      width: 48px;
      height: 48px;
      padding: 12px;
      box-sizing: border-box !important;
      font-size: 28px;

      @include m.bp(null, sm) {
        transform: scale(1.06);
        transition: transform 0.32s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }

      @include m.bp(sm, md) {
        width: 56px;
        height: 56px;
        font-size: 32px;
      }

      @include m.bp(lg) {
        width: 56px;
        height: 56px;
        font-size: 32px;
      }
    }

    &__label {
      font-size: 16px;
      letter-spacing: 0.64px;
      line-height: 24px;
      font-weight: bold;

      @include m.bp(null, md) {
        display: none;
      }

      @include m.bp(lg) {
        font-size: 20px;
        letter-spacing: 0.72px;
        line-height: 28px;
      }
    }
  }

  &__radio {
    display: contents;
    width: 0;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }

  &__content {
    @include m.card-style;
    @include m.flex(stretch, between, column-reverse);
    position: relative;
    grid-column: 1 / -1;
    grid-row: 2;
    margin-top: 16px;
    transition: opacity 1.6s cubic-bezier(0, 0.55, 0.45, 1), filter 0.88s cubic-bezier(0, 0.55, 0.45, 1);

    @include m.bp(sm) {
      @include m.grid(6, 12, 8, 12);
      grid-row: 1 / -1;
      align-self: center;
      height: auto;
      margin-top: 64px;
    }

    @include m.bp(md) {
      grid-column: 5 / -1;
      margin: auto 0;
    }

    @include m.bp(xl) {
      grid-column: 7 / -1;
    }

    .heading {
      grid-column: 1 / -1;
      padding: 16px 12px;
      margin: 0;
      z-index: 20;

      @include m.bp(null, sm) {
        grid-row: 2;
      }

      @include m.bp(sm) {
        grid-column: 1 / 8;
        padding: 24px 16px;
      }

      @include m.bp(lg) {
        padding: 32px 24px;
        grid-column: 1 / 5;
      }

      @include m.bp(xl) {
        grid-column: 1 / 8;
      }

      &__title {
        padding-top: 0;
      }
    }

    .picture {
      grid-column: 1 / -1;
      grid-row: 1;
      flex: 1 1 280px;
      border-bottom-left-radius: unset;
      z-index: 10;

      @include m.bp(null, sm) {
        border-bottom-right-radius: unset;
      }

      @include m.bp(sm) {
        @include m.position(absolute, 0, 0, 0);
        grid-column: -6 / -1;
        border-top-left-radius: unset;
        width: 100%;
        height: 100%;
      }

      @include m.bp(lg) {
        grid-column: -5 / -1;
      }

      @include m.bp(xl) {
        grid-column: -6 / -1;
      }

      // Image sizing and positioning
      [data-image-fit=contain] {
        padding: 16px;
        box-sizing: border-box;
      }
    }
  }

  // Show and hide based on active state
  input:not(:checked) ~ & {

    &__content {
      opacity: 0;
    }

    @include m.bp(null, md) {
      &__preview .switch-list-block__preview__icon {
        opacity: 32%;
        transform: scale(0.88);
      }
    }

    &__preview {
      &:before, &:after {
        opacity: 0;
      }
    }
  }

  input:checked ~ &__preview:before {
    animation: 4s preview-background-progress;
  }
}

// grid preview positions
@include m.bp(null, md) {
  @for $i from 1 through 10 {
    [data-switch-index='#{$i}'] {
      grid-column: $i;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  @for $i from 1 through 10 {
    [data-switch-rows='#{$i}'] {
      grid-template-columns: repeat($i, 1fr);
    }
  }
}

@include m.bp(md) {
  @for $i from 1 through 10 {
    [data-switch-index='#{$i}'] {
      grid-row: $i;
    }

    [data-switch-rows='#{$i}'] {
      grid-template-rows: repeat($i, 1fr);
    }
  }
}

// Animations
@keyframes preview-background-progress {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 30%;
  }
}