@use 'sass:list';
@use '../variables' as v;
@use 'experimental' as v-ex;

/*
 *  # SCSS Layout Mixins V1.0
 *  A set of mixins to rapidly build layouts.
 *
 */

/*
 * # Breakpoints
 * Add styling for specific screen sizes.
 *
 * $minSize - Set the min-width property | options: xs, sm, md, lg, xl, xxl
 * $maxSize - Set the max-width property | options: xs, sm, md, lg, xl, xxl
 *
 */
$breakpoints: (xs: 374px, sm: 768px, md: 1024px, lg: 1280px, xl: 1600px, xxl: 1920px);

@mixin bp($minSize: null, $maxSize: null) {

  // Creates a 'between' option with a min and max breakpoint.
  @if $minSize != null and $maxSize != null {

    @media (min-width: #{map-get($breakpoints, $minSize)}) and (max-width: #{map-get($breakpoints, $maxSize) - 1}) {
      @content;
    }

    // Creates a min-width breakpoint.
  } @else if $minSize != null {

    @media (min-width: #{map-get($breakpoints, $minSize)}) {
      @content;
    }

    // Creates a max-width breakpoint that's 1px smaller than a normal breakpoint.
  } @else if $maxSize != null {

    @media (max-width: #{map-get($breakpoints, $maxSize) - 1}) {
      @content;
    }

  }

}


/*
 * # Flex layout
 * Create flex layouts inside a container element.
 *
 *
 */
@mixin flex($justify:center, $align:center, $direction:null, $display:flex) {
  $flex-pos-map: (
          start: flex-start,
          center: center,
          end: flex-end,
          between: space-between,
          around: space-around,
          evenly: space-evenly,
          stretch: stretch,
  );

  display: $display;
  justify-content: map-get($flex-pos-map, $justify);
  align-items: map-get($flex-pos-map, $align);
  @if $direction {
    flex-direction: $direction
  }
}


/*
 * # Grid containers
 * Create grid views inside a container element.
 *
 * @param Integer $col-xs - Set the amount of columns for xs and default screen sizes
 * @param Integer $col-sm - Set the amount of columns for sm screen sizes
 * @param Integer $col-lg - Set the amount of columns for lg screen sizes
 * @param Integer $col-xl - Set the amount of columns for xl and xxl screen sizes
 * @param {px, %} $max-col-width - Overwrite the default max width on large screens
 */
@mixin grid($col-xs: 6, $col-sm: 12, $col-lg: 18, $col-xl: 18, $max-col-width: null) {
  display: grid;
  grid-template-columns: repeat($col-xs, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;

  @include bp(sm) {
    grid-column-gap: 12px;
    grid-template-columns: repeat($col-sm, 1fr);
  }

  @include bp(lg) {
    grid-column-gap: 16px;
    grid-template-columns: repeat($col-lg, 1fr);
  }

  @include bp(xl) {
    grid-column-gap: 16px;
    grid-template-columns: repeat($col-xl, 1fr);
  }

  @include bp(xxl) {
    @if ($max-col-width) {
      grid-template-columns: repeat($col-lg, $max-col-width);
    }
  }
}


/*
 * # Element positioning
 * Shortcuts for absolute, fixed and sticky positioning.
 *
 * $position  - String - Either 'fixed', 'absolute' or 'sticky' to set the css position property.
 * $top       - String - Set the value for the top property.    | Default: null
 * $right     - String - Set the value for the right property.  | Default: null
 * $bottom    - String - Set the value for the bottom property. | Default: null
 * $left      - String - Set the value for the left property.   | Default: null
 */
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {

  @if ($position == 'fixed' or $position == 'absolute' or $position == 'sticky') {

    position: #{$position};
    @if $top {
      top: $top
    }
    @if $right {
      right: $right
    }
    @if $bottom {
      bottom: $bottom
    }
    @if $left {
      left: $left
    }

  } @else {

    @error ('The position mixin only accepts \'fixed\',  \'absolute\' and \'sticky\'. \'#{$position}\' isn\'t a valid value.');

  }

}

@mixin scroll-box($sizes: (xs, sm)) {

  @include v-ex.inputMethod(touch) {
    @include v-ex.hide-scrollbars;
    overflow-y: scroll;

    @each $size in $sizes {

      @if $size == xs {

        @include scroll-box-sizing($size);

      } @else {

        @include bp($size) {
          @include scroll-box-sizing($size);
        }

      }

    }
  }
}

@mixin scroll-box-sizing($size) {
  margin-left: -#{map-get(v.$section-padding-sizes, $size)};
  margin-right: -#{map-get(v.$section-padding-sizes, $size)};
  padding-left: map-get(v.$section-padding-sizes, $size);
  padding-right: map-get(v.$section-padding-sizes, $size);
}

/*
 * # Section Padding Sizes
 * Renders margin or padding sizes for a container.
 *
 * $mode - either margin or padding.
 */
@mixin section-padding-sizes($mode: padding) {

  @if $mode != padding and $mode != margin {
    @error "Property #{$mode} must be either 'margin' or 'padding'.";
  }

  #{$mode}-left: map-get(v.$section-padding-sizes, xs);
  #{$mode}-right: map-get(v.$section-padding-sizes, xs);

  @include bp(sm) {
    #{$mode}-left: map-get(v.$section-padding-sizes, sm);
    #{$mode}-right: map-get(v.$section-padding-sizes, sm);
  }

  @include bp(md) {
    #{$mode}-left: map-get(v.$section-padding-sizes, md);
    #{$mode}-right: map-get(v.$section-padding-sizes, md);
  }

  @include bp(lg) {
    #{$mode}-left: map-get(v.$section-padding-sizes, lg);
    #{$mode}-right: map-get(v.$section-padding-sizes, lg);
  }
}