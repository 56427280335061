@use '../../../../../../resources/scss/mixins' as m;

.contact-options-block {
  grid-auto-flow: dense;
  min-height: 320px;

  // TODO: Move to general selector
  &[data-background] {
    border-radius: var(--shape-border-radius);
  }

  &--card-background {
    background: var(--cards-background);
    border: 1px solid var(--card-border);
  }

  &__copy {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    align-self: center;
    z-index: 10;
    margin: 16px;

    @include m.bp(sm) {
      padding: 64px 0;
      margin: 0 24px;
    }

    @include m.bp(xl) {
      margin: 0 32px;
    }

    @include m.bp(lg) {
      margin: 0 40px;
    }

    &--align {
      &-center {
        align-items: center;
        text-align: center;
      }

      &-right {
        align-items: flex-end;
      }
    }

    .heading__title {
      padding-top: 0;
    }

    &__actions {
      display: grid;
      grid-template-columns: 1fr;
      grid-column: 1 / -1;
      gap: 8px;
      margin: 16px 0 0;

      @include m.bp(sm) {
        grid-template-columns: repeat(2, 1fr);
        width: auto;
        margin: 24px 0 0;
        gap: 16px;

        .button {
          flex-direction: column;
          padding: 16px 12px;
          gap: 16px;

          .icon {
            width: 48px;
            height: 48px;
          }
        }
      }

      @include m.bp(md) {
        grid-template-columns: repeat(4, auto);

        .button {
          flex-direction: column;
          padding: 16px 24px;
          gap: 24px;

          .icon {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }

  &__background {
    @include m.position(absolute, 0, 0, 0, 0);
    height: 100%;
    z-index: 0;

    &:after {
      @include m.position(absolute, 0, 0, 0, 0);
      content: '';
      background: rgba(255, 255, 255, 0.56);
      border-width: var(--banner-border, 0);
      border-style: solid;
      border-color: var(--banner-border);
      border-radius: var(--shape-border-radius);
    }

    &__heading {
      text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    }

    &__picture {
      width: 100%;
      height: 100%;

      .image {
        height: 100%;
        border-radius: 0;
      }
    }
  }

  &[data-dark-mode] &__background:after {
    background: rgba(0, 0, 0, 0.56);
  }
}