@use '../../../../../../resources/scss/mixins' as m;

.highlights {
  grid-row-gap: 24px;

  &__container {
    @include m.flex(center, center);
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
    grid-column: 1 / -1;

    .highlight {
      @include m.flex(start, center, column);
      flex: 1 0 auto;
      text-align: center;
      align-self: start;

      > .icon {
        color: var(--brand-main);
        font-size: 48px;
        height: 48px;
      }

      &__label {
        padding: 0.64rem 0 0;
        font-size: 1rem;
      }

      &__description {
        margin-top: 4px;
        font-size: 0.8rem;
      }

      .link * {
        font-size: 0.8rem;
      }
    }
  }
}