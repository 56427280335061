/*
 *  # Experimental SCSS Styling Mixins V1.0
 *  A collection of non standard mixins.
 *
 *  Warning!  Some of these mixins use non-standard properties to solve specific cases,
 *            while other mixins impact user accessibility. These mixins should be used carefully
 *            or not at all when more stable options are available.
 */


/*
 * # Hide Scrollbars
 * Hide scrollbars inside the current element.
 *
 * Warning! This mixin impacts accessibility by hiding scrollbars inside an scrolling element.
 *          Make sure to use a replacement element to indicate the scroll position.
 */
@mixin hide-scrollbars {

  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}


/*
 * # Browser Exceptions
 * Create styling for specific browsers.
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *          Only use this if nothing else can solve your case.
 *
 * $browser - String - set the browser name write styling for. | Options: firefox, chrome, safari, internet-explorer
 */
@mixin browser($browser) {
  @if $browser == firefox {
    @media all and (min--moz-device-pixel-ratio: 0) {
      @content;
    }
  } @else if $browser == chrome {
    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
      @content;
    }
  } @else if $browser == safari {
    @media not all and (min-resolution: .001dpcm) {
      @content;
    }
  } @else if $browser == internet-explorer {
    @media screen and (min-width: 0 \0
    ) {
      @content;
    }
  }
}


/*
 * # Detects the users inputMethod
 * Only activate your styling when a certain inputMethod is used
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *
 */
@mixin inputMethod($type) {

  @if $type == touch {
    @media only screen and (hover: none) and (pointer: coarse) {
      @content;
    }
  }

  @if $type == mouse {
    @media only screen and (hover: hover) and (pointer: fine) {
      @content;
    }
  }
}


/*
 * # Set styling when interacting with an element
 *
 * $mode (optional)
 *        - active -  uses the :active selector instead of :focus and :hover.
 *        - focus  -  only sets :focus and :focus-within.
 *
 * Warning! This feature is still experimental and thus subject to possible changes in the future.
 *
 */
@mixin interaction($mode: null) {

  @if ($mode == active) {

    &:active {
      @content;
    }

  } @else if ($mode == focus) {

    &:focus, &:focus-within {
      @content;
    }

  } @else {

    &:focus, &:focus-within {
      @content;
    }

    @include inputMethod(mouse) {
      &:hover {
        @content;
      }
    }
  }
}