@use '../../../../../../resources/scss/variables' as v;
@use '../../../../../../resources/scss/mixins' as m;

.featured-list {

  &__grid {
    @include m.grid(2, 4, 6, 6);
    grid-column: 1 / -1;
    gap: 8px;
    margin-top: 16px;

    @include m.bp(sm) {
      gap: 16px;
    }
  }

  .scroll-handler-container__slider {
    @include m.flex(start, center);

    @include m.bp(sm) {
      gap: 16px;
    }
  }

  &:not([data-card-background]) .scroll-handler-container__slider {
    gap: 32px;

    @include m.bp(sm) {
      gap: 64px;
    }

    @include m.bp(lg) {
      gap: 80px;
    }
  }

  @include m.bp(sm) {
    .scroll-handler-container {

      &__slider {
        mask-image: linear-gradient(to right, transparent, black 128px, black calc(100% - 128px), transparent);
      }

      &__buttons {
        @include m.position(absolute, 0, 0, 0, 0);
      }
    }
  }
}

.slider-item {
  @include m.flex(center, center);
  width: 100%;
  height: 100%;
  max-height: 128px;
  margin: 0;
  box-sizing: border-box;
  border-radius: var(--shape-border-radius);

  &--background {
    background: var(--featured-list-background);
    border: 1px solid var(--featured-list-border);
    padding: 12px;

    @include m.bp(sm) {
      padding: 16px;
    }
  }

  &--background#{&--slider} {
    padding: 24px 16px;
  }

  &--slider {
    flex: 1 0 auto;
    width: auto;
    height: 80px;
    min-width: 128px;
    max-width: 160px;

    @include m.bp(sm) {
      height: 96px;
    }
  }

  &[href] {
    cursor: pointer;

    @include m.interaction() {
      .picture {
        transform: scale(0.92);
      }
    }
  }

  &--background[href] {
    @include m.interaction() {
      border-color: var(--brand-main-active);
      background: rgba(var(--brand-main-active-rgb), 0.08);
    }
  }

  &[data-monochrome] {
    filter: grayscale(1);
    opacity: 0.64;
    transition: filter 0.24s, opacity 0.24s, transform 0.24s;

    @include m.interaction() {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  .picture {
    height: 100%;
    min-width: 128px;
    max-width: 240px;
    pointer-evets: none;
    transition: inherit;
    border-radius: unset;

    .image {
      object-fit: contain;
    }
  }
}