@use '../../../../../../resources/scss/mixins' as m;

.media-block {

  .picture, .video {
    grid-column: 1 / -1;

    @include m.bp(sm) {
      grid-column: 2 / -2;
    }

    @include m.bp(lg) {
      grid-column: 3 / -3;
    }

    @include m.bp(xl) {
      grid-column: 5 / -5;
    }
  }
}